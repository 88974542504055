<template>
  <div class="card-wrapper" v-for="data in nftCardData" :key="data.key">
    <div class="card">
      <router-link :to="data.url">
        <img :src="data.imgUrl" alt="" />
        <div class="card-bottom">
          <h4>{{ data.title }}</h4>
          <p>{{ data.content }}</p>
        </div>
        <div
          class="card-more"
          v-on:mouseover="handleMouseover()"
          v-on:mouseleave="handleMouseleave"
        >
          <h5>Learn more</h5>
          <div class="card-more-img"></div>
          <img :src="arrowData" alt="" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import nft1Image from "@/assets/images/home/pic_nft1.png";
import nft2Image from "@/assets/images/home/pic_nft2.png";
import nft3Image from "@/assets/images/home/pic_nft3.png";
import nft4Image from "@/assets/images/home/pic_nft4.png";
import nft5Image from "@/assets/images/home/pic_nft5.png";
import arrowImage from "@/assets/images/home/arrow.png";
import arrowImage2 from "@/assets/images/home/arrow3.png";
export default {
  name: "nft-card",
  data: () => {
    return {
      nftCardData: [
        {
          key: 1,
          imgUrl: nft1Image,
          title: "Non-Fungible Tokens (NFT's)",
          url: "/nft-detail1",
          content:
            "Not to be misunderstood as a “Non-fundible token ”, a Non-fungible token, or NFT's for short, is yet another feature supported by blockchain technology. ",
        },
        {
          key: 2,
          imgUrl: nft2Image,
          title: "So what can I do with an NFT?",
          url: "/nft-detail2",
          content:
            "Great question, imagine playing a video game where you play against other players from around the world.",
        },
        {
          key: 3,
          imgUrl: nft3Image,
          url: "/nft-detail3",
          title:
            "What's to stop me from taking a screenshot of someone else's NFT?",
          content: "Yes, it is true. Technically you could do this. ",
        },
        {
          key: 4,
          imgUrl: nft4Image,
          url: "/nft-detail4",
          title: "So what's a blue chip NFT?",
          content:
            "In simple terms, an NFT that is referred to as a blue chip NFT is one that is expected to be a long term good investment. ",
        },
        {
          key: 5,
          imgUrl: nft5Image,
          url: "/nft-detail5",
          title: "What to expect from NTF's in the future",
          content:
            "With a flourishing Metaverse, the future of NFT's is bright. ",
        },
      ],
      arrowData: arrowImage,
    };
  },
  methods: {
    handleMouseover() {
      this.arrowData = arrowImage2;
    },
    handleMouseleave() {
      this.arrowData = arrowImage;
    },
  },
};
</script>

<style lang="less" scoped>
.card-wrapper {
  margin-left: 20px;
  position: relative;
  width: 386px;
  height: 329px;
  margin-top: 32px;
}
.card {
  width: 386px;
  // height: 329px;

  cursor: pointer;
  display: flex;
  flex-direction: column;
  & > a {
    position: absolute;
    display: flex;
    flex-direction: column;
    &:hover {
      box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      top: -20px;
      .card-more {
        display: flex;
        &:hover {
          h5 {
            color: #ffd200;
          }
        }
      }
    }
  }
  &-bottom {
    color: #161616;
    height: 149px;
    background: #fafafa;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-left: 24px;
    & > h4 {
      font-family: TechnaSans-Regular, TechnaSans;
      padding-top: 20px;
      width: 338px;
    }
    & > p {
      margin-top: 10px;
      width: 338px;
    }
  }
  &-more {
    height: 40px;
    display: flex;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;
    & > h5 {
      color: #161616;
    }
    & > img {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
