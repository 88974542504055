<template>
  <common-detail :imgUrl="imgUrl" :list="list" />
  <div class="nft">
    <p></p>
    <h4>
      Many wonder, "Can't I screenshot the NFT without purchasing?" This is
      true, but you would not be able to sell it at the value of the original.
      Similarly, if you took a photo of the Mona Lisa it would be challenging to
      find a collector. Every time the NFT moves on the secondary market, the
      new owner and the price paid is automatically recorded on the blockchain,
      which is a digital archive of transactions no one can alter and everyone
      can see. The idea is that by having these certificates of authenticity be
      publicly available for everyone to view online, NFTs can guarantee the
      provenance of any asset they are connected to.
    </h4>
  </div>
</template>

<script>
import CommonDetail from "@/components/commonDetail/index.vue";
import nft3Image from "@/assets/images/detail/nft3.png";
export default {
  name: "ntf-detail3",
  components: {
    CommonDetail,
  },
  data: function () {
    return {
      imgUrl: nft3Image,
      list: [
        {
          title:
            "What's to stop me from taking a screenshot of someone else's NFT?",
          content: [
            "Yes, it is true. Technically you could do this. Similarly, you could buy a print of a Monet painting, but that doesn't mean that you have the original. Everytime an NFT has been sold, the recording of the transaction is automatically in the blockchain. Ensuring that the originality and ownership to the owner as transactions on the blockchain can not be altered but can be viewed in the public domain. This public archive of transactional history",
            "Which means that when there is a new owner, the ownership can be proven in the blockchain.",
          ],
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.nft {
  width: 1200px;
  margin: 0 auto;
  margin-top: -48px;
  padding: 0 20px;
  // padding-left: 80px;
  display: flex;
  flex-direction: row;
  padding-bottom: 38px;
  p {
    width: 8px;
    height: 8px;
    background: #161616;
    color: #161616;
    border-radius: 100%;
    margin-right: 10px;
    margin-top: 10px;
  }
  h4 {
    color: #161616;
    width: 100%;
  }
}
</style>
