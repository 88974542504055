<template>
  <div class="trading">
    <div class="trading-content">
      <h2 class="trading-title">Trading</h2>
      <h5 class="trading-text">
        {{
          "As you would with fiat currencies, trading is just as common in the crypto world."
        }}
      </h5>
      <learn-more url="trading-detail" />
    </div>
  </div>
</template>

<script>
import LearnMore from "@/components/learn-more/index.vue";
export default {
  name: "trading-v",
  components: {
    LearnMore,
  },
};
</script>

<style lang="less" scoped>
.trading {
  height: 408px;
  background: url("@/assets/images/home/pic_trading.png") no-repeat;
  background-size: cover;
  margin-top: 80px;
  &-content {
    width: 1200px;
    margin: 0 auto;
    color: #fcfcfd;
    padding-top: 125px;
    h2 {
      font-family: TechnaSans-Regular, TechnaSans;
      margin-bottom: 10px;
      // opacity: 0.95;
      font-weight: 400;
    }
    h5 {
      width: 510px;
      margin-bottom: 32px;
      // opacity: 0.85;
    }
  }
}
</style>
