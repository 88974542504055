<template>
  <div class="learn">
    <router-link :to="{ path: url, query: { anchor: anchor } }">
      <p>Learn more</p>
      <img src="@/assets/images/home/shape.png" alt="" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "learn-more",
  props: {
    url: String,
    anchor: {
      type: String,
      required: false,
    },
  },
};
</script>
<style lang="less" scope>
.learn {
  width: 154px;
  height: 34px;
  background: #ffffff;
  border-radius: 17px;
  cursor: pointer;
  &:hover {
    background: #ffd200;
  }
  & > a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & > p {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #161616;
      line-height: 22px;
    }
    & > img {
      width: 14px;
      height: 14px;
      margin-left: 10px;
    }
  }
}
</style>
