<template>
  <common-detail :imgUrl="imgUrl" :list="list" />
</template>

<script>
import CommonDetail from "@/components/commonDetail/index.vue";
import nft1Image from "@/assets/images/detail/nft1.png";
export default {
  name: "ntf-detail1",
  components: {
    CommonDetail,
  },
  data: function () {
    return {
      imgUrl: nft1Image,
      list: [
        {
          title: "Non-Fungible Tokens (NFT's)",
          content: [
            "Not to be misunderstood as a “Non-fundible token ”, a Non-fungible token, or NFT's for short, is yet another feature supported by blockchain technology. But what is it and why have I been hearing more about it lately? An NFT is a way to determine the origin and the originality of a digital asset of which is not a cryptocurrency. The “Non-fungible” part refers to the inability of an NFT to be exchanged with something of equal value. For example, if I were to exchange a $10 note with two $5 notes, this exchange would be of equal value and therefore exchangeable. However, if you are dealing with a unique, original asset, for example, an original piece of artwork, this can not be exchanged so easily as it would be difficult to exchange artwork with the same value of originality or uniqueness. Now consider this with a digital piece of artwork, a gif, or someone who has uploaded an original song. Yes it could be sold at a particular price, but just like the original piece of artwork, the owner would want to maintain ownership and the rights to the originality of the work. This also isn't easy when you consider that digital artworks or songs could be endlessly duplicated, copied and sent on the internet. However, owning the NFT of a gif or digital artwork specifies the owner and rights of the work.",
            "In many ways, this can be a win for artists out there. A new medium to create and showcase digital art in all its forms which is transparent and properly monetised. Streaming companies have come under scrutiny for profiting off original content creators without properly reimbursing them. Depending on the NFT, an artist could sell their work and/or receive royalties every time the work gets resold later on.",
          ],
        },
      ],
    };
  },
};
</script>
