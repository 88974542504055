<template>
  <common-detail :imgUrl="imgUrl" :list="list" />
</template>

<script>
import CommonDetail from "@/components/commonDetail";
import ethImage from "@/assets/images/detail/pic_metaverse.png";
export default {
  name: "metaverse-detail",
  components: {
    CommonDetail,
  },
  data: () => {
    return {
      imgUrl: ethImage,
      list: [
        {
          title: "The Metaverse",
          anchor: "metaverse1",
          content: [
            "In simple terms, the Metaverse is a hyper realistic, digital universe which is underpinned by the use of augmented reality (AR) and virtual reality (VR) technology. The Metaverse is a place which combines the digital, with the physical, to create a new medium where interconnectivity and interactivity are key. In many ways, we are already on our way to transitioning into the Metaverse. Video games are a good representation of a more immersive version of the internet, where our virtual character (also known as an avatar), interacts with other players from all over the world at the same time. This concept of an immersive, real-time version of the internet is one of the distinguishing features of the Metaverse.",
            "The term “Metaverse” was first coined in the novel “Snow Crash” written by Neal Stephenson back in 1992 which depicts a virtual universe which you can enter with your digital avatar. Although it may have sounded very futuristic, it has since become a reality. Facebook has rebranded itself as Meta while Microsoft has invested millions in developing its services and technology towards a Metaverse based platform.",
          ],
        },
        {
          title:
            "So what exactly is the difference with the internet we use today?",
          anchor: "metaverse2",
          content: [
            "We all know what the internet is. You’re using it right now. But how would you define it? The Internet is the connection or network of billions of other digital devices, to promote interconnectivity around the world. Obtaining information and communicating remotely has never been easier.",
            " Of course, the Metaverse is completely based on the internet, but with some key defining features. The Metaverse is a hyper-realistic digital environment with holographic representations of things, people, and places. Interactions that happen via these representations (avatars) are supported by virtual and augmented reality technology and Blockchain technology. In simple terms, think of the internet as something you do from the outside looking in, while the Metaverse is something you are completely immersed in.",
          ],
        },
        {
          title: "So what can you do in the Metaverse?",
          anchor: "metaverse3",
          content: [
            "Imagine being able to attend a concert or fashion show from the comfort of your home. You can do this now while wearing a virtual reality headset and other sensory technology to enter this immersive environment. You could buy and display your NFT artwork, buy virtual investment property, or different outfits for your avatar. It is presumably limitless what you can do in the Metaverse due to the scalability and adaptability of the technology. This promotes a virtual economy in which people can sell, buy, advertise, and exchange virtual goods. This is all supported by Blockchain technology to ensure the safe transfer of capital and ownership.",
          ],
        },
      ],
    };
  },
};
</script>
