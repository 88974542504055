<template>
  <div class="smart">
    <div class="article">
      <div class="article-content">
        <div
          v-if="'smart0' === $route.query.anchor"
          style="background: rgba(255, 193, 64, 0.1)"
          class="article-content-graph"
          id="smart0"
        >
          <h2>What is a Smart Contract?</h2>
          <h5 class="graph" v-for="p in graph" :key="p">{{ p }}</h5>
        </div>
        <div v-else class="article-content-graph" id="smart0">
          <h2>What is a Smart Contract?</h2>
          <h5 class="graph" v-for="p in graph" :key="p">{{ p }}</h5>
        </div>
        <div class="article-content-mage">
          <img src="@/assets/images/detail/pic_Smart.png" />
        </div>
      </div>
      <ul>
        <li v-for="data in list" :key="data.key" :id="data.anchor">
          <div
            v-if="$route.query.anchor && data.anchor === $route.query.anchor"
            style="background: rgba(255, 193, 64, 0.1)"
          >
            <h2>{{ data.title }}</h2>
            <h4 v-for="c in data.content" :key="c">{{ c }}</h4>
          </div>
          <div v-else>
            <h2>{{ data.title }}</h2>
            <h4 v-for="c in data.content" :key="c">
              {{ c }}
              <a
                v-if="data.anchor === 'smart5'"
                target="_blank"
                href="https://xsc.xt.com"
                >XT.com</a
              >
            </h4>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "blockchain-detail",
  data() {
    return {
      graph: [
        "So, to find out what's so smart about Smart Contracts, you will first need to know some basics about Blockchain Technology. If you’re not already familiar with Blockchain Technology, XT Academy has got you covered. Click here and have a look at how Blockchain works. For those here who just need a refresher, Blockchain Technology is a decentralised ledger network which can transfer and certify the acquisition of assets without the necessity of a centralised authority like a bank or government. ",
        "Now with that out of the way, Smart Contracts are a self-implementing contract where the terms of the contract are written in code and executed via the Blockchain network.",
        " As per the nature of Blockchain Technology, there is no central authority to police the contract or to ensure the terms are met. Instead, the contract is controlled according to the predetermined terms and conditions encrypted in code and executed on the Blockchain network. Although it may seem scary to have no central authority to police the parties involved, we can be rest assured that the execution of the contract will only occur if, and only if the predetermined terms are met. This means that you can be assured of the outcome you had previously agreed to are programmed to be honored and no transaction will take place unless the terms are met. Also thanks to the Blockchain network, all transactions are immutable, traceable, and irreversible.",
      ],

      list: [
        {
          title: "How do Smart Contracts Work?",
          anchor: "smart1",
          content: [
            "As with any contract, there are predetermined conditions which both parties agree to before committing to the contract. In the case of Smart Contracts, the terms and conditions of the contract are encrypted in code into a block. Once, and only once the terms of the contract have been fulfilled will the contract be programmed to be executed and the transaction takes place.",
            "For example, let's say we are trying to crowdfund for a project. We determine in code that we are aiming for $100,000 worth of cryptocurrency and in return, depending on how much individuals contribute, they will own shares in the company. If the crowdfunding fails to amount to $100,000 by the predetermined time, the Smart Contract will automatically return the exact funds to those who did contribute and no shares will be distributed. This is due to the fact that the conditions encrypted into the Smart Contract were not obtained and therefore couldn’t be executed. This is all achieved automatically, without the need of an intermediary to mediate, thanks to the Blockchain network and the code stipulated within the block. Likewise, if the terms of the Smart Contract are met by the predetermined time and conditions, the funds would automatically be issued to the company crowdfunding and the shares would be distributed to those who contributed. ",
            "The concept of Smart Contracts can be applied in a variety of scenarios. Issuing insurance claims, trading, and of course, investing.",
          ],
        },
        {
          title:
            "So how do we incorporate external factors into a Smart Contract?",
          anchor: "smart2",
          content: [
            "As you have read so far, Blockchain is fundamental in determining the outcome of the Smart Contract. But what if the terms are dependent on external factors? What if the outcome of the Smart Contract was reliant upon the results of the stock market, or the future price of a given commodity? The information of these events do not magically appear within the Blockchain network. Instead, it requires a Blockchain Oracle, a third party entity of which provides the necessary information for the Smart Contract to be executed. Essentially a bridge between the digital, and the real world. Or in this case, off-chain and on-chain. ",
            "Let’s look at an example. Let’s say that there are two football fans, this weekend is the grand final and the two football fans are going to make a bet as to who’ll win. They decide to make the bet using a Smart Contract, but will need a Blockchain Oracle to import the information on the outcome of the match into the Blockchain. Without this crucial information, the Smart Contract would not be able to award the winner of the bet as it is hinged to the result of the match.",
            "Clearly, the Grand final is an event outside of the Blockchain which is why an Oracle, via a trusted APL (Application Programming Interface) is necessary. What’s an API you ask? It is a code which is programmed to allow two applications to share information and bridge into the Blockchain. ",
          ],
        },
        {
          title: "Who came up with the idea of a Smart Contract?",
          anchor: "smart3",
          content: [
            "The concept of Smart Contracts was first proposed back in 1994 by a computer scientist named Nick Szabo. You may have heard of this guy. He is famed for inventing the virtual currency “Bit Gold” a full 10 years before Bitcoin was developed. ",
            "Anyway, Szabo’s motivation in creating Smart Contracts was to establish a computerised contract for assets such as bonds and derivatives. Smart Contracts better standardise the terms whilst the computerisation on Blockchain helps to reduce the processing fees and remove the intermediaries.",
          ],
        },

        {
          title: "Are Smart Contracts safe? ",
          anchor: "smart4",
          content: [
            "As Smart Contracts are supported by the Blockchain network, it becomes nearly impossible to tamper or alter its content as the Blockchain network would immediately pick up on this. The concern with any contract, would be the event where one or more parties backtrack, tamper, or try to reinterpret the conditions of the contract. The code encrypted into the Smart Contract is programmed to deliver only what is coded in the contract, relieving any ambiguity as to the outcomes of the contract. So yes, the thought of not having an intermediary may seem a bit scary, but there is assurance and guarantee that a Smart Contract will perform as programmed and as long as terms are met. In fact, being decentralised is the ultimate warranty as the validity and security can be cross-verified in the blockchain network. ",
          ],
        },
        {
          title: "Keen to get started using Smart Contracts?",
          anchor: "smart5",
          content: ["We’re glad you asked. Come check out Smart Contracts on"],
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
