<template>
  <div class="home">
    <banner />
    <div class="content">
      <currency />
      <ethereum />
      <ido />
      <metaverse />
      <nft />
    </div>
    <block-chain />
    <smart-contract />
    <trading />
    <glossary />
  </div>
</template>

<script>
import Banner from "./components/banner/index.vue";
import Currency from "./components/currency/index.vue";
import Ethereum from "./components/ethereum/index.vue";
import Ido from "./components/ido/index.vue";
import Metaverse from "./components/metaverse/index.vue";
import Nft from "./components/nft/index.vue";
import BlockChain from "./components/block-chain/index.vue";
import SmartContract from "./components/smart-contract/index.vue";
import Trading from "./components/trading/index.vue";
import Glossary from "./components/glossary/index.vue";
export default {
  name: "HomeView",
  components: {
    Banner,
    Currency,
    Ethereum,
    Ido,
    Metaverse,
    Nft,
    BlockChain,
    SmartContract,
    Trading,
    Glossary,
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
