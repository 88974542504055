<template>
  <div
    :class="[cardType === 'leftCard' ? 'left-card' : 'right-card']"
    class="card"
  >
    <div class="card-left">
      <h3 v-if="cardType === 'leftCard'">
        {{
          "So what exactly is the difference with the internet we use today?"
        }}
      </h3>
      <h3 v-else>
        {{ "So what can you do in the Metaverse?" }}
      </h3>
    </div>
    <div class="card-right">
      <router-link
        :to="{ path: 'metaverse-detail', query: { anchor: anchor } }"
      >
        <div class="img"></div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "metaverse-card",
  props: {
    cardType: String,
  },
  data: function () {
    return {
      anchor: this.cardType === "leftCard" ? "metaverse1" : "metaverse2",
    };
  },
};
</script>

<style scoped lang="less">
.card {
  width: 590px;
  height: 226px;
  display: flex;
  background-size: contain;
  border-radius: 8px;
  background: url("@/assets/images/home/metaverse2.png") no-repeat;
  &:hover {
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
    background: url("@/assets/images/home/metaverse1.png") no-repeat;
  }
  &-left {
    display: flex;
    width: 303px;
    color: #161616;
    line-height: 30px;
    font-family: TechnaSans-Regular, TechnaSans;
    align-items: flex-start;
    margin-left: 40px;
    margin-top: 40px;
  }
  &-right {
    display: flex;
    align-items: flex-end;
    margin-left: 95px;
    margin-bottom: 27px;
    .img {
      width: 100px;
      height: 100px;
      cursor: pointer;
      background: url("@/assets/images/home/arrow.png") no-repeat;
      &:hover {
        background: url("@/assets/images/home/arrow2.png") no-repeat;
      }
    }
  }
}
// .left-card {
//   background: url("@/assets/images/home/metaverse1.png") no-repeat;
// }
// .right-card {
//   background: url("@/assets/images/home/metaverse2.png") no-repeat;
// }
</style>
