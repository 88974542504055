<template>
  <common-detail :imgUrl="imgUrl" :list="list" />
  <div class="nft">
    <p></p>
    <h4>
      While the history of NFTs is intriguing, the future of NFTs has endless
      opportunities as the new space transitions from raw and experimental to
      exceedingly more useful and mainstream. Through tokenization,
      programmability, collaboration, royalties, and more direct connections
      between artists and collectors, NFTs may soon be a technology vital to
      everyday life. Concepts like DAOs, token-based Metaverses, community owned
      financial protocols, and NFT art were small scale experiments just a few
      years ago. Now they represent multi-billion dollar communities that
      combine protocol-driven design, economics, and governance as global
      collectives on the internet. It may be challenging to predict the future,
      but for digital art collecting, it's safe to say we have yet to imagine
      the extent of what will emerge in decades to come.
    </h4>
  </div>
</template>

<script>
import CommonDetail from "@/components/commonDetail/index.vue";
import nft5Image from "@/assets/images/detail/nft5.png";
export default {
  name: "ntf-detail5",
  components: {
    CommonDetail,
  },
  data: function () {
    return {
      imgUrl: nft5Image,
      list: [
        {
          title: "What to expect from NTF's in the future",
          content: [
            "With a flourishing Metaverse, the future of NFT's is bright. We've witnessed NFT's transition from being something merely experimental, to something practical and on its way to becoming mainstream.",
          ],
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.nft {
  width: 1200px;
  margin: 0 auto;
  margin-top: -48px;
  padding: 0 20px;
  // padding-left: 80px;
  display: flex;
  flex-direction: row;
  padding-bottom: 58px;
  p {
    width: 8px;
    height: 8px;
    background: #161616;
    color: #161616;
    border-radius: 100%;
    margin-right: 10px;
    margin-top: 10px;
  }
  h4 {
    color: #161616;
    width: 100%;
  }
}
</style>
