<template>
  <common-detail :imgUrl="imgUrl" :list="list" />
</template>

<script>
import CommonDetail from "@/components/commonDetail";
import ethImage from "@/assets/images/detail/pic_Eth.png";
export default {
  name: "ethereum-detail",
  components: {
    CommonDetail,
  },
  data: () => {
    return {
      imgUrl: ethImage,
      list: [
        {
          title: "Ethereum",
          anchor: "ethereum1",
          content: [
            "You could think of Ethereum as Bitcoin's little brother. If someone is going to mention something about crypto other than Bitcoin, it'll probably be Ethereum. Ethereum is a decentralised computing system with its own unique blockchain program. Like Bitcoin, it works on a decentralised system, free from any centralised authority and is run over thousands of machines all over the world. Just like with Bitcoin, you are able to transfer cryptocurrency using Ethereum, however, the list of what you can do with Ethereum does not end there. Due to the flexible nature of the Ethereum platform, there are many kinds of sophisticated programs that can be based on and launched on the platform including other cryptocurrencies.",
          ],
        },
        {
          title: "So what's the difference - Ethereum vs. Bitcoin",
          anchor: "ethereum2",
          content: [
            "Bitcoin is the trailblazer of decentralised blockchain technology. It proved the viability of the decentralised, permissionless technology to safely exchange currency and information. However, Bitcoin is often referred to as the first generation when it comes to blockchain technology. Bitcoin's Blockchain technology wasn't intended to be a particularly sophisticated system, which has proven to be a positive trait when it comes to security. It's kept intentionally rigid to ensure the integrity of its security and avoid misuse. However, the stringent system has also proven to lack the ability to support and accommodate other applications.This is where Ethereum comes in. Often referred to as the second generation of Blockchain technology, Ethereum's blockchain technology is scalable and can host a variety of programs outside simple transactions. Its programmability enables greater compatibility and has provided the foundations for other cryptocurrencies as well.",
          ],
        },
        {
          title: "What is Ether (ETH) and how is it different from Ethereum?",
          content: [
            "For the sake of simplicity, Ether is like the cryptocurrency of Ethereum. It is true that many people will refer to Etherium as a cryptocurrency, but Ethererum is the protocol itself, not a cryptocurrency. If you want to use a service on the Ethereum platform, be it programming or digital services, you will need to pay for the computational processing of the service in Ether. The greater the computational requirement, the more you will need to pay in Ether. Although ETH is only accepted on the Ethereum platform, it is currently valued as the second highest valued cryptocurrency (first being Bitcoin) by trading volume. Furthermore, ETH can always be traded into Bitcoin, or other Altcoins and vice versa.",
          ],
          anchor: "ethereum3",
        },
        {
          title: "Cryptography",
          anchor: "ethereum4",
          content: [
            "To understand what Cryptography is, let's first discuss the origins of the word. Cryptography refers to two ancient Greek words, Kryptos and Graphein, meaning “hidden” and “to write” respectively. The technology at the backbone of cryptocurrency, Blockchain, uses cryptography to enter the transaction details into the system. As the Greek words refer, the transaction details entered into the blockchain are effectively “hidden” to ensure anonymity and security in the transaction.",
          ],
        },
      ],
    };
  },
};
</script>
