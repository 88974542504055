<template>
  <div class="metaverse">
    <div class="metaverse-top">
      <div class="metaverse-top-left">
        <h2>The Metaverse</h2>
        <p>
          {{
            "In simple terms, the Metaverse is a hyper realistic, digital universe which is underpinned by the use of augmented reality (AR) and virtual reality (VR) technology.The Metaverse is a place which combines the digital, with the physical, to create a new medium where interconnectivity and interactivity are key. In many ways, we are already on our way to transitioning into the Metaverse. Video games are a good representation of a more immersive version of the internet, where our virtual character (also known as an avatar), interacts with other players from all over the world at the same time. This concept of an immersive, real-time version of the internet is one of the distinguishing features of the Metaverse."
          }}
        </p>
        <div>
          <router-link :to="{ path: 'metaverse-detail' }">
            <p>Learn more</p>
            <img src="@/assets/images/home/shape2.png" alt="" />
          </router-link>
        </div>
      </div>
      <div class="metaverse-top-right">
        <a target="_blank" href="https://www.youtube.com/watch?v=ILOo2K7TNAw">
          <div class="play"></div
        ></a>
      </div>
    </div>
    <div class="metaverse-bottom">
      <metaverse-card cardType="leftCard" />
      <metaverse-card />
    </div>
  </div>
</template>

<script>
import MetaverseCard from "@/components/metaverse-card/index.vue";
export default {
  name: "metaverse-v",
  components: {
    MetaverseCard,
  },
};
</script>

<style lang="less" scoped>
.metaverse {
  margin-top: 110px;
  display: flex;
  flex-direction: column;

  &-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-left {
      width: 548px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        margin-bottom: 10px;
        font-family: TechnaSans-Regular, TechnaSans;
      }

      & > div {
        margin-top: 17px;
        a {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;
          color: #161616;
          font-size: 14px;
        }
        :hover {
          color: #ffd200;
        }
        img {
          width: 8px;
          height: 12px;
          margin-left: 10px;
        }
      }
    }

    &-right {
      width: 590px;
      height: 322px;
      background: url("@/assets/images/home/video.png") no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      & > a {
        display: block;
        width: 172px;
        height: 172px;
        cursor: pointer;
        .play {
          width: 100%;
          height: 100%;
          background: url("@/assets/images/home/play.png");
          &:hover {
            background: url("@/assets/images/home/play2.png");
          }
        }
      }
    }
  }

  &-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
}
</style>
