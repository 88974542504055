<template>
  <div class="blockchain">
    <div class="article">
      <div class="article-content">
        <div class="article-content-graph" id="blockchain0">
          <h2>Blockchain Technology</h2>
          <h5 class="graph" v-for="p in graph" :key="p">{{ p }}</h5>
        </div>
        <div class="article-content-mage">
          <img src="@/assets/images/detail/blockchaintechnology.png" />
        </div>
      </div>
      <ul>
        <li v-for="data in list" :key="data.key" :id="data.anchor">
          <div
            v-if="$route.query.anchor && data.anchor === $route.query.anchor"
            style="background: rgba(255, 193, 64, 0.1)"
          >
            <h2>{{ data.title }}</h2>
            <h4 v-for="c in data.content" :key="c">{{ c }}</h4>
          </div>
          <div v-else-if="data.anchor !== 'blockchain1'">
            <h2>{{ data.title }}</h2>
            <h4 v-for="c in data.content" :key="c">
              {{ c }}
            </h4>
          </div>
          <div v-else>
            <h2>{{ data.title }}</h2>
            <h4>Fundamentally, each block contains:</h4>
            <div class="point">
              <h5>•</h5>
              <p>
                <span>Data.</span>
                There may be some variation on how and what kind of data is
                stored in a single block. However, for the sake of simplicity, a
                Bitcoin data would be the details of the sender, the receiver,
                and the amount being transferred in the transaction.
              </p>
            </div>
            <div class="point">
              <h5>•</h5>
              <p>
                <span>Hash.</span>
                The Hash is like a code which is encrypted into each block and
                both identifies the block and its contents. Every hash is
                completely unique and is even sometimes referred to as a
                fingerprint. If there are any changes to the block, the hash
                intern will also be altered and the block will now be considered
                a different block.
              </p>
            </div>
            <div class="point">
              <h5>•</h5>
              <p>
                <span>Hash of the previous block.</span>
                This is the part that chains the blocks together. Each block
                contains the hash of the previous block with exception of
                course, to the very first block in a chain, which is called the
                genesis block.
              </p>
            </div>
            <h4 style="margin-top: 20px" v-for="c in data.content" :key="c">
              {{ c }}
            </h4>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "blockchain-detail",
  data() {
    return {
      graph: [
        "Blockchain Technology is the fundamental infrastructure when it comes to decentralisation, cryptocurrencies, and more. Put simply, blockchain technology is a public ledger which facilitates the ownership and transition of digital assets and information without the need for an intermediary like a bank or government. This is all achieved whilst maintaining transparency, traceability, and security. Described as the next revolution in technology, Blockchain is paving a new era of the internet.",
        "As the name suggests, Blockchain is a connection of blocks, all of which contains information. It was originally intended to act as a timelog, effectively keeping the minutes of information for later reference. The concept was first coined in 1991, but remained mostly unused until 2009 when Bitcoin adopted the technology to facilitate its operations. Since then, Blockchain has evolved and different variations have been created to accommodate different products and services (we’ll get into that later on). ",
      ],

      list: [
        {
          title: "So how do these blocks work?",
          anchor: "blockchain1",
          content: [
            "The linkage in the chain is important. Any alteration in a block will automatically change the hash and since each block contains the hash of the previous block, any tampering or manipulation can be immediately identified as it would interfere with the sequencing of the blocks. This is how Blockchain can maintain a secure platform where transactions can take place without the need for intermediaries like banks or governments. ",
          ],
        },
        {
          title: "Now that we know that, how does the blockchain system work?",
          anchor: "blockchain2",
          content: [
            "In simple terms, Blockchain Technology is a public ledger, a shared database with entries that need to be cross-verified by a network of nodes. You could think of it like a Google doc which needs verification from all parties and with each entry sheet connected with the previous sheet. Once all parties agree with the terms of the Google sheet, it is deemed valid and the system will move onto the next sheet. This cross-verification on this decentralised domain is called Peer-2-Peer (P2P) where intermediaries are redundant and nodes (Peers) themselves can confirm the block. This is how consensus is achieved and blocks are validated.",
            "Additionally, one of the potential problems that cryptocurrency faces is the risk of double spending. For example, if I only have $100 in my account and I send it all to pay a bill, a centralised system would recognise that I'm no longer able to spend any more money since I simply don’t have any to do so. On a decentralised system, Proof of Work (PoW), among other things, works to ensure that double spending cannot occur as this would devalue, and undermine the legitimacy of the cryptocurrency. ",
          ],
        },

        {
          title: "Is Blockchain Safe?",
          anchor: "blockchain3",
          content: [
            "The nodes in the blockchain system are a collection of computers around the world, churning through block after block, verifying the content of a given transaction. The thought of personal information connected to the transaction on this public domain without a centralised authority may seem daunting at first. Especially when we are dealing with money.",
            "To ensure security and confidence in the technology, blockchain uses what's called a public key, and a private key. Anyone using Blockchain must have both of these keys in order to send and receive on the Blockchain. Lets use a mailbox as an analogy, if you walk down a suburban street, you could place mail into someone's mailbox. In a way, it is public, but to access the private content of the mailbox, the owner may have a key to ensure that only he/she can see it. The transferring of information can take place whilst still maintaining the privacy of all parties.",
          ],
        },

        {
          title: "What problems does blockchain solve?",
          anchor: "blockchain4",
          content: [
            "The application of blockchain goes far beyond NFT’s and cryptocurrencies. The advantages of implementing and utilising blockchain technology is being recognised in a variety of sectors. As the technology eliminates intermediaries, is secure, and can potentially save companies money, it's becoming a no-brainer to adopt this technology. Companies, Governments, and ironically banks themselves are getting in on the action. ",
            "Since blockchain technology is able to securely transfer information and assets, national elections could theoretically allow online voting, or even mitigate conversion and transfer fees when sending remittance. The more you read about cryptocurrencies and the technologies associated, the more you’ll discover the variety of applications Blockchain Technology can be applied.",
          ],
        },
        {
          title: "Peer-to-peer (P2P)",
          anchor: "blockchain5",
          content: [
            "As you have read so far, Blockchain technology eliminates the need for intermediaries. But how it achieves this is through a mechanism called Peer-to-peer, or P2P for short. These “peers” are the nodes in the Blockchain system which communicate with each other, checking the validity of the blocks in the system and are doing so without an intermediary. Peer-to-peer is the cornerstone which enables consensus among the nodes, and therefore, decentralisation of the platform. ",
          ],
        },
        {
          title: "Blockchain Mining",
          anchor: "blockchain6",
          content: [
            "In a nutshell, blockchain mining is the process of validating and reaching consensus among the nodes on the internet regarding a transaction to intern put it in a block to add to the Blockchain. A cryptocurrency miner is one of the nodes in the network. In other words, there is no digging involved with this kind of mining. Instead, miners can be thought of as a node that audits the transactions, establishes a consensus on the system, then adds the information in a block to the blockchain. This is done by each miner racing to find a solution to the equation and prove the legitimacy of the transaction. Once this is achieved, the other nodes in the system also validate the algorithm and then add it to the blockchain system. As an incentive, the miner will be paid in cryptocurrency and the cycle continues.",
            "This is just a basic summary of how blockchain mining works. Stay tuned as there will be more information in the future regarding mining and much more. ",
          ],
        },
        {
          title: "Proof of Work (PoW)",
          anchor: "blockchain7",
          content: [
            "One of the ways to validate and confirm a transaction on the Blockchain system is through what’s called Proof of work, or PoW for short. Proof of Work is when a network of crypto miners, or nodes, compete to solve a complicated algorithm which will confirm the content of a block. Once a crypto miner has solved the algorithm for a block, it is then confirmed among the other nodes. Once the block has been solved and confirmed, it will then be added to the chain of blocks and the process can repeat again. ",
            "One of the downsides of Proof of Work is the enormous amount of electricity it uses and thus its carbon footprint. Its collective electricity usage is even comparable to that of entire countries. This is due to what can be described as a fault in the system. As all miners are trying to solve the algorithm of the block at the same time, it means that a lot of energy is being used and arguably wasted as only one of the miners will solve the puzzle and will get the reward. In a way, however, this energy intensive system helps to maintain the decentralisation of the network. If there were someone who tried to manipulate the system and validate incorrect blocks, this would require having over 51% of the blockchain system. The cost of electricity and computational hardware required to achieve this would be monumental and basically unachievable. Preventing any chances of hackers and those with malicious intentions.",
            "Furthermore, to have a better chance of getting the rewards associated with solving the algorithm, some miners join together to create what's called mining pools which increases the chances of solving the algorithm and sharing the rewards. However, this starts to centralise a system whose purpose is to be decentralised. A decentralisation of the system is what guarantees security and more verification. ",
            "It is worth noting that the Proof of Work system is what facilitates the likes of Bitcoin and other major cryptocurrencies. ",
          ],
        },
        {
          title: "Proof of Stake (PoS)",
          anchor: "blockchain8",
          content: [
            "The objective of Proof of stake is similar to that of Proof of Work in that they aim to confirm and validate a block to add it to the chain. However, the mechanisms used in Proof of Stake (PoS) differ from Proof of Work . Unlike PoW where miners compete with one another to solve algorithms, PoS has an election process which selects a “Validator” to complete the algorithm, thus saving the energy associated with all miners trying to solve the same puzzle simultaneously. It is also worth mentioning the difference in terminology, in the PoW system, a miner mines, in the PoS system, a Validator mints or forges the blocks to be added to the chain. ",
            "In order to become a validator, the node must first make a deposit of some cryptocurrency as a “stake” into the system. The higher the stake, the higher the chance the vendor will be selected to mint the next block. This stake means that the vendor has a personal vested interest in ensuring that they validate the block correctly and do not conduct fraudulent activities. This may seem unfair as the system is programmed to favour those who are able to afford paying a high stake. However, the same could also be said regarding PoW as the system favours those who can afford to cover the higher electricity bills and more powerful hardware. ",
            "Once a vendor has been selected and the have successfully minted the block, the fees associated with the block (ie. the transaction fees) will be rewarded to the vendor. ",
          ],
        },
        {
          title: "Where to for Blockchain? ",
          anchor: "blockchain9",
          content: [
            "Variations of Blockchain technology have already been developed to address different applications or downfalls of their predecessors. Bitcoin was the first to truly utilise the technology, however it has been criticized for its excessive requirements of electricity and the consequential environmental impacts. Ethereum too is another Blockchain system, which was originally designed to be more adaptable to new applications like the introduction of NFT’s and requires less computational power to process. ",
          ],
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
