<template>
  <div class="ido">
    <img src="@/assets/images/home/pic_blockchain.png" alt="" />
    <div class="ido-content">
      <h2>Initial DEX Offering (IDO)</h2>
      <h5>
        {{
          "If there is a crypto related project in need of capital, one of the methods to obtain it is through Initial DEX Offering, or IDO."
        }}
      </h5>
      <learn-more url="ido-detail" />
    </div>
  </div>
</template>

<script>
import LearnMore from "@/components/learn-more/index.vue";
export default {
  name: "ido-v",
  components: {
    LearnMore,
  },
};
</script>

<style lang="less" scoped>
.ido {
  margin-top: 80px;
  height: 340px;
  position: relative;
  color: #ffffff;
  &:hover {
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
  }
  &-content {
    position: absolute;
    top: 90px;
    left: 90px;

    & > h2 {
      font-size: 32px;
      font-family: TechnaSans-Regular, TechnaSans;
      font-weight: 400;
      margin-bottom: 10px;
      // opacity: 0.95;
    }

    & > h5 {
      width: 510px;
      font-size: 16px;
      margin-bottom: 32px;
      // opacity: 0.85;
    }
  }
}
</style>
