<template>
  <router-link :to="{ path: data.url, query: { anchor: data.anchor } }">
    <div class="card">
      <img class="bg" :src="data.imgUrl" alt="" />
      <h4>{{ data.title }}</h4>
      <div class="icon"></div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ethereum-card",
  props: {
    data: Object,
  },
};
</script>

<style lang="less" scoped>
.card {
  position: relative;
  width: 100%;
  height: 100%;
  &:hover {
    .icon {
      background: url("@/assets/images/home/arrow3.png");
    }
  }
  & > h4 {
    position: absolute;
    width: 221px;
    top: 20px;
    left: 32px;
    font-size: 20px;
    font-family: TechnaSans-Regular, TechnaSans;
    color: #ffffff;
    font-weight: 400;
  }
  .icon {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 32px;
    bottom: 20px;
    background: url("@/assets/images/home/arrow4.png");
  }
}
</style>
