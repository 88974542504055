<template>
  <div class="glossary">
    <div class="glossary-content">
      <h2>Glossary</h2>
      <ul>
        <li v-for="data in glossaryData" :key="data.title">
          <h4>{{ data.title }}</h4>
          <p>{{ data.content }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "glossary-v",
  data() {
    return {
      glossaryData: [
        {
          title: "Altcoin:",
          content: "A crypto currency which is an alternative to Bitcoin",
        },
        {
          title: "Decentralised Exchange (DEX):",
          content:
            "Is a variation of cryptocurrency exchange which enables direct peer-to-peer (P2P) crypto transactions to be performed securely.",
        },
        {
          title: "Fiat Currency:",
          content: "Government back currency like USD or Pound",
        },
        {
          title: "Initial DEX Offering (IDO):",
          content:
            "Is a decentralised and permissionless crowdfunding platform often used to launch a new coin or token.",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.glossary {
  background: #f6f6f5;
  padding-bottom: 80px;
  &-content {
    padding-top: 110px;
    margin: 0 auto;
    width: 1200px;
    color: #161616;
    & > h2 {
      font-family: TechnaSans-Regular, TechnaSans;
      margin-bottom: 34px;
    }
    & > ul {
      display: flex;
      flex-direction: column;
      & > li {
        padding: 26px 32px 24px 32px;
        border-radius: 8px;
        opacity: 0.72;
        margin-top: 10px;
        background: #ffffff;
        h4 {
          margin-bottom: 2px;
          font-family: TechnaSans-Regular, TechnaSans;
        }
        &:hover {
          box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
</style>
