<template>
  <div class="smart">
    <div class="smart-top">
      <h2>Smart Contract</h2>
      <div>
        <router-link to="/smart-contract">
          <p>View All</p>
          <img src="@/assets/images/home/shape2.png" alt="" />
        </router-link>
      </div>
    </div>
    <div class="smart-bottom">
      <img src="@/assets/images/home/pic_Smart.png" alt="" />
      <div class="smart-bottom-right">
        <ul>
          <li v-for="data in list" :key="data.name">
            <router-link
              :to="{ path: data.url, query: { anchor: data.anchor } }"
            >
              <div></div>
              <h4>
                {{ data.name }}
                <img src="@/assets/images/home/shape4.png" alt="" />
              </h4>
            </router-link>
          </li>
        </ul>
        <h4>
          Keen to get started using Smart Contracts? Let's go to
          <a target="_blank" href="https://xsc.xt.com">XT.com</a>
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "smart-contract",
  data() {
    return {
      list: [
        {
          name: "What is a Smart Contract?",
          url: "/smart-contract",
          anchor: "smart0",
        },
        {
          name: "How do Smart Contracts Work?",
          url: "/smart-contract",
          anchor: "smart1",
        },
        {
          name: "So how do we incorporate external factors into a Smart Contract?",
          url: "/smart-contract",
          anchor: "smart2",
        },
        {
          name: "Who came up with the idea of a Smart Contract?",
          url: "/smart-contract",
          anchor: "smart3",
        },
        {
          name: "Are Smart Contracts safe?",
          url: "/smart-contract",
          anchor: "smart4",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
// .wrapper {
//   width: 100%;
// }
.smart {
  width: 1200px;
  margin: 0 auto;
  margin-top: 80px;
  &-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-family: TechnaSans-Regular, TechnaSans;
      font-weight: 400;
      color: #161616;
      line-height: 41px;
    }
    & > div {
      display: flex;
      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        &:hover {
          p {
            color: #ffc140;
          }
        }
      }
      p {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #161616;
        line-height: 20px;
      }
      img {
        width: 8px;
        height: 12px;
        margin-left: 10px;
      }
    }
  }
  &-bottom {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    font-family: TechnaSans-Regular, TechnaSans;
    font-weight: 400;
    color: #161616;
    line-height: 26px;
    & > img {
      width: 550px;
      height: 328px;
      margin-right: 62px;
    }
    &-right {
      padding: 10px 0;
      height: 328px;
      ul {
        li {
          display: flex;
          flex-direction: row;
          margin-bottom: 32px;
          align-items: center;
          cursor: pointer;
          & > a {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #161616;
            &:hover {
              color: #ffc140;
              img {
                display: block;
              }
            }
          }
          div {
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background: #000;
            margin-right: 20px;
            margin-left: 10px;
          }
          h4 {
            position: relative;
            img {
              position: absolute;
              top: 3px;
              right: -34px;
              width: 20px;
              height: 20px;
              margin-left: 10px;
              display: none;
            }
          }
        }
      }
      & > h4 {
        a {
          font-size: 20px;
          font-family: TechnaSans-Regular, TechnaSans;
          font-weight: 400;
          color: #ffc140;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
