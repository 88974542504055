<template>
  <common-detail :imgUrl="imgUrl" :list="list" />
</template>

<script>
import CommonDetail from "@/components/commonDetail/index.vue";
import nft4Image from "@/assets/images/detail/nft4.png";
export default {
  name: "ntf-detail4",
  components: {
    CommonDetail,
  },
  data: function () {
    return {
      imgUrl: nft4Image,
      list: [
        {
          title: "So what's a blue chip NFT?",
          content: [
            "In simple terms, an NFT that is referred to as a blue chip NFT is one that is expected to be a long term good investment. The value of an NFT can depend on many things. Scarcity of the NFT, collectability, and of course, demand. If you are planning to make an investment by buying NFT’s, you must first evaluate its perceived future value. If its value is expected to increase in the future and bring a good return if sold later on, then that NFT can be referred to as a blue chip NFT. Of course, there is risk involved trying to guess which NFT will be a blue chip, but like all financial investments, there’s always an element of risk. ",
          ],
        },
      ],
    };
  },
};
</script>
