<template>
  <div class="ethereum">
    <div class="ethereum-top">
      <h2>Ethereum</h2>
      <router-link :to="{ path: 'ethereum-detail' }">
        <div>
          <p>View All</p>
          <img src="@/assets/images/home/shape2.png" alt="" />
        </div>
      </router-link>
    </div>
    <div class="ethereum-bottom">
      <ul>
        <router-link :to="{ path: '/ethereum-detail' }">
          <li class="ethereum-bottom-first">
            <img src="@/assets/images/home/ethereum.png" alt="" />
            <h4>What is Ethereum?</h4>
          </li>
        </router-link>
        <li v-for="data in ethereumCardData" :key="data.key">
          <ethereum-card :data="data" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import EthereumCard from "@/components/ethereum-card/index.vue";
import ethereum1Image from "@/assets/images/home/ethereum1.png";
import ethereum2Image from "@/assets/images/home/ethereum2.png";
import ethereum3Image from "@/assets/images/home/ethereum3.png";
export default {
  name: "ethereum-v",
  components: {
    EthereumCard,
  },
  data: () => {
    return {
      ethereumCardData: [
        {
          key: 1,
          title: "So what's the difference - Ethereum vs. Bitcoin?",
          imgUrl: ethereum1Image,
          url: "/ethereum-detail",
          anchor: "ethereum2",
        },
        {
          key: 2,
          title: "What is Ether (ETH) and how is it different from Ethereum?",
          imgUrl: ethereum2Image,
          url: "/ethereum-detail",
          anchor: "ethereum3",
        },
        {
          key: 3,
          title: "what is Cryptography?",
          imgUrl: ethereum3Image,
          url: "/ethereum-detail",
          anchor: "ethereum4",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.ethereum {
  margin-top: 80px;

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > h2 {
      font-size: 32px;
      font-family: TechnaSans-Regular, TechnaSans;
      font-weight: 400;
      color: #161616;
      line-height: 41px;
    }

    p {
      &:hover {
        color: #ffd200;
      }
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      p {
        font-size: 14px;
        color: #161616;
      }

      img {
        width: 8px;
        height: 12px;
        margin-left: 10px;
      }
    }
  }

  &-bottom {
    margin-top: 44px;

    & > ul {
      display: flex;
      flex-direction: row;

      & > li {
        width: 285px;
        height: 133px;
        position: relative;
        margin-left: 20px;
        border-radius: 8px;
        &:hover {
          box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
        }
        & > img {
          width: 100%;
          height: 100%;
        }
      }

      .ethereum-bottom-first {
        width: 285px;
        margin-left: 0;
        position: relative;
        img {
          width: 100%;
        }
        & > h4 {
          position: absolute;
          width: 93px;
          top: 43px;
          right: 45px;
          font-size: 20px;
          font-family: TechnaSans-Regular, TechnaSans;
          font-weight: 400;
          color: #ffffff;
          line-height: 26px;
        }
      }
    }
  }
}
</style>
