function postMessage(height) {
  !height && (height = document.documentElement.scrollHeight);
  window.parent.postMessage(
    JSON.stringify({
      type: "heightChanged",
      params: { height },
    }),
    "*"
  );
}

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function $cre(parEle, tag, argObj) {
  !tag && (tag = "div");
  if (typeof tag !== "string") return;

  const ele = document.createElement(tag);

  if (argObj && typeof argObj === "object") {
    for (let va in argObj) {
      if (va === "style" && typeof argObj[va] === "object") {
        for (let vaSub in argObj[va]) ele[va][vaSub] = argObj[va][vaSub];
      } else {
        ele[va] = argObj[va];
      }
    }
  }

  if (parEle) {
    if (typeof parEle === "string") parEle = document.getElementById(parEle);
    parEle.appendChild && parEle.appendChild(ele);
  }

  return ele;
}

export default function (ele) {
  if (!inIframe()) return;

  postMessage();

  try {
    const resizeObserver = new ResizeObserver((entries) => {
      const height = entries[0].target.scrollHeight;
      postMessage(height);
    });
    resizeObserver.observe(ele || document.documentElement);
  } catch (e) {
    const iframe = $cre(ele || document.body, "iframe", {
      style: {
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        height: "100%",
        width: "0",
        border: "none",
        background: "transparent",
      },
    });
    iframe.contentWindow.onresize = function () {
      const height = document.documentElement.scrollHeight;
      postMessage(height);
    };
  }
}
