<template>
  <div class="xtacademy">
    <div class="topImage"></div>
    <div class="article">
      <div class="article-content">
        <div class="article-content-graph">
          <h5 class="graph" v-for="p in graph" :key="p">{{ p }}</h5>
        </div>
        <div class="article-content-mage">
          <img src="@/assets/images/detail/pic_Cryptocurren_2.png" />
        </div>
      </div>
      <ul>
        <li v-for="data in list" :key="data.key" :id="data.anchor">
          <div
            v-if="$route.query.anchor && data.anchor === $route.query.anchor"
            style="background: rgba(255, 193, 64, 0.1)"
          >
            <h2>{{ data.title }}</h2>
            <h4 v-for="c in data.content" :key="c">{{ c }}</h4>
          </div>
          <div v-else>
            <h2>{{ data.title }}</h2>
            <h4 v-for="c in data.content" :key="c">{{ c }}</h4>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "xtacademy-detail",
  data() {
    return {
      graph: [
        "Whether you've already got some cryptocurrency knowledge, or you're just curious as to what the hype is about crypto, XTAcademy is here for you.",
        "For those who are new to crypto, more questions than answers may arise when you start your crypto knowledge journey, but be rest assured, it does become clearer as you progress. Our tutorials and content will explain it every step of the way. The world of crypto is fast evolving with new currencies popping up and changes to the regulatory landscape. It is XTAcademy's mission to equip you with the knowledge and understanding of all things crypto. Enabling you to buy and trade with confidence and with trust that XT.com will support you every step of the way. From understanding how blockchain works, the acronyms you'll come across, and the jargon you might not have heard of, we've got it here.",
        "Our tutorials and content have been structured in a progressive way (beginner, intermediate, and advanced) to ensure you have the prerequisite understanding and knowledge of how this industry works and how you can get involved.",
        "To get started on XT.com, remember to sign up and follow us on our socials to get the latest updates, content, and news.",
      ],

      list: [
        {
          title: "what is Cryptocurrency?",
          anchor: "xtacademy0",
          content: [
            "Let's get started with the most obvious question, what is Cryptocurrency and why are so many people talking about it?",
            "A Cryptocurrency, (also known as crypto) is a virtual or digital currency which uses cryptography (hence the crypto part) to secure the integrity of the currency. Just like the cash in your wallet, you can use crypto to make purchases or add it to your investment portfolio. The validation of a crypto exchange is secured using what's called Blockchain Technology, a ledger which verifies and enables the transaction to occur. With Blockchain technology, cryptocurrency transfers can be made secure and without the need of an intermediary body, like a bank or government. The more traditional online payment methods are owned by organisations which usually require you to first transfer the money to them, then they transfer the money on your behalf when you make a payment. As we mentioned before, there isn't a middle man in the process. Instead, there's a whole web of computers verifying and processing the transaction.",
          ],
        },
        {
          title: "So what's the difference with normal currency?",
          anchor: "xtacademy1",
          content: [
            "Many of you might be wondering, what's wrong with the USD, or the Euro? What can Cryptocurrency do that the cash in my wallet can't do? These are very valid questions and being able to distinguish the difference will help you better appreciate why cryptocurrencies have been gaining such popularity. Cryptocurrencies can be purchased, sold, earnt, won, and exchanged just like you can with fiat currencies (non-digital, government backed currency that you have in your wallet). However, the platform is decentralised and unlike fiat currencies, is not government backed or valued against commodities such as gold. Since it isn't government backed, it means that cryptocurrencies are mostly immune to influences such as inflation or economic turmoil. Furthermore, cryptocurrency is effectively an international currency. Once you have some crypto, you could circumvent the fees and hassle associated with international currency conversion.",
            "Indeed, there are some elements of the crypto world which are still in their infancy, and the regulatory environment still needs to catch up, but the list of institutions and governments accepting crypto is ever growing, meaning a future with crypto in some way, shape, or form, is inevitable. Might as well get some crypto now right?",
          ],
        },

        {
          title:
            "We can't talk about Cryptocurrency without talking about Bitcoin",
          anchor: "xtacademy2",
          content: [
            "When you hear about cryptocurrency, the first thing that will probably come to your mind is Bitcoin. Bitcoin has been a trailblazer in the crypto world. Turning crypto from a mere concept, into a mainstream investment option. Since being launched back in 2009, Bitcoin proved the viability and credibility a digital currency can be. Paired with Blockchain technology, Bitcoin demonstrated that a digital currency is not only viable, but revolutionary in the world of finance.",
          ],
        },

        {
          title: "So who started Bitcoin you might ask",
          anchor: "xtacademy3",
          content: [
            "Blockchain Technology is a digital recording mechanism which is connected to a network of computers. This technology enables transactions to take place without the need of an intermediary such as a bank, government, or third party. To secure the integrity and security of the process, a digital ledger records the list of transactions using blocks which are linked together (hence, blockchain). This system is append-only, meaning that information can only be added to the system, not deleted. Once some information has been recorded in a block, it becomes very difficult to alter or tamper the information, as the information becomes interconnected with other blocks in the sequence. If the data in one block gets tampered with, it would therefore alter the subsequent block, deeming it invalid and alerting the system. Another important feature Blockchain provides is its ability to prevent double spending of a given digital currency. For example, we can easily share and exchange pictures and videos between friends online, effectively duplicating the original content. This is easy to do in the online world, but also provides a challenge to cryptocurrency as duplication would effectively devalue and illegitimise the currency. This is where Blockchain Technology comes in. It prevents the possibility of double spending via cryptography.",
          ],
        },

        {
          title: "What is this Blockchain Technology I keep hearing about?",
          content: [
            "Blockchain Technology is a digital recording mechanism which is connected to a network of computers. This technology enables transactions to take place without the need of an intermediary such as a bank, government, or third party. To secure the integrity and security of the process, a digital ledger records the list of transactions using blocks which are linked together (hence, blockchain). This system is append-only, meaning that information can only be added to the system, not deleted.  Once some information has been recorded in a block, it becomes very difficult to alter or tamper the information, as the information becomes interconnected with other blocks in the sequence. If the data in one block gets tampered with, it would therefore alter the subsequent block, deeming it invalid and alerting the system. Another important feature Blockchain provides is its ability to prevent double spending of a given digital currency. For example, we can easily share and exchange pictures and videos between friends online, effectively duplicating the original content. This is easy to do in the online world, but also provides a challenge to cryptocurrency as duplication would effectively devalue and illegitimise the currency. This is where Blockchain Technology comes in. It prevents the possibility of double spending via cryptography. ",
          ],
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
