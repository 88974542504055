<template>
  <div class="ido">
    <img src="@/assets/images/detail/pic_blockchain.png" alt="" />
    <div class="ido-text">
      <p>
        If there is a crypto related project in need of capital, one of the
        methods to obtain it is through Initial DEX Offering, or IDO. It is a
        relatively new type of decentralised and permissionless platform. The
        majority of projects aim to launch a coin or token via a decentralised
        exchange, meaning that it is performed Peer-to-Peer (P2P) and without an
        intermediary body. Enabling a new way to fundraise in the crypto space.
      </p>
      <p>
        When an organisation wants to launch an IDO, this means the project aims
        to launch a token or coin through this decentralised liquidity exchange.
        This takes place as a liquidity pool where traders can exchange tokens,
        crypto coins, and stablecoins. IDO’s are the successor of ICO’s (Initial
        Coin Offerings), and IEO’s (Initial Exchange Offerings), however, with
        some key differences. ICO’s for instance, have a lack of screening by an
        intermediary, which potentially poses a threat to investors. IEO’s, on
        the other hand, centralising was the main concern as it could lead to a
        higher risk of susceptibility to theft and cyber scams. For these
        reasons, IDO’s quickly became popular among those launching a new coin
        or token.
      </p>
      <h5>
        Wanting to see a great example of an IDO in action? Click here to see
        how you can get involved with IDO with our XTstarter.
      </h5>
    </div>
  </div>
</template>
<style lang="less" scoped>
.ido {
  width: 1200px;
  margin: 0 auto;
  padding-top: 58px;
  padding-bottom: 58px;
  &-text {
    margin-top: 58px;
    p,
    h5 {
      font-size: 20px;
      color: #161616;
      line-height: 28px;
    }
    h5 {
      margin-top: 40px;
    }
  }
}
</style>
<script>
export default {
  name: "ido-detail",
  data: function () {
    return {};
  },
};
</script>
