import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/home/index.vue";
import XtacademyDetail from "@/views/xtacademy/index.vue";
import EthereumDetail from "@/views/ethereum/index.vue";
import MetaverseDetail from "@/views/metaverse/index.vue";
import NftDetail1 from "@/views/nft1/index.vue";
import NftDetail2 from "@/views/nft2/index.vue";
import NftDetail3 from "@/views/nft3/index.vue";
import NftDetail4 from "@/views/nft4/index.vue";
import NftDetail5 from "@/views/nft5/index.vue";
import IdoDetail from "@/views/ido/index.vue";
import tradingDetail from "@/views/trading/index.vue";
import blockchainDetail from "@/views/blockchain/index.vue";
import smartContract from "@/views/smart-contract/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/xtacademy-detail",
    name: "xtacademy-detail",
    component: XtacademyDetail,
  },
  {
    path: "/ethereum-detail",
    name: "ethereum-detail",
    component: EthereumDetail,
  },
  {
    path: "/metaverse-detail",
    name: "metaverse-detail",
    component: MetaverseDetail,
  },
  {
    path: "/nft-detail1",
    name: "nft-detail1",
    component: NftDetail1,
  },
  {
    path: "/nft-detail2",
    name: "nft-detail2",
    component: NftDetail2,
  },
  {
    path: "/nft-detail3",
    name: "nft-detail3",
    component: NftDetail3,
  },
  {
    path: "/nft-detail4",
    name: "nft-detail4",
    component: NftDetail4,
  },
  {
    path: "/nft-detail5",
    name: "nft-detail5",
    component: NftDetail5,
  },
  {
    path: "/ido-detail",
    name: "ido-detail",
    component: IdoDetail,
  },
  {
    path: "/trading-detail",
    name: "trading-detail",
    component: tradingDetail,
  },
  {
    path: "/blockchain-detail",
    name: "blockchain-detail",
    component: blockchainDetail,
  },
  {
    path: "/smart-contract",
    name: "smart-contract",
    component: smartContract,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.parent.postMessage(
      JSON.stringify({
        type: "heightChanged",
        params: { height: document.getElementById("app")?.scrollHeight },
      }),
      "*"
    );
    if (to.query.anchor) {
      const ele: any = to.query.anchor;
      window.parent.postMessage(
        JSON.stringify({
          type: "scrollTop",
          params: { value: document.getElementById(ele)?.offsetTop },
        }),
        "*"
      );
      return {
        el: "#" + to.query.anchor,
        behavior: "smooth",
      };
    } else {
      window.parent.postMessage(
        JSON.stringify({
          type: "scrollTop",
          params: { value: 0 },
        }),
        "*"
      );
      return { top: 0 };
    }
  },
});

export default router;
