<template>
  <div class="blockchain">
    <div class="blockchain-content">
      <h2>Blockchain Technology</h2>
      <h5>
        Blockchain Technology is truly the backbone when it comes to
        cryptocurrencies, NFT’s, the Metaverse, and much more.
      </h5>
      <div class="blockchain-content-more">
        <router-link to="/blockchain-detail">
          <p>Learn more</p>
          <img src="@/assets/images/home/shape3.png" alt="" />
        </router-link>
      </div>
      <ul>
        <li>
          <div v-for="data in list1" :key="data.name">
            <router-link
              :to="{
                path: data.url,
                query: { anchor: data.anchor },
              }"
            >
              <img :src="data.img" alt="" />
              <h4>{{ data.name }}</h4>
              <img
                class="right-icon"
                src="@/assets/images/home/shape4.png"
                alt=""
              />
            </router-link>
          </div>
        </li>
        <li>
          <div v-for="data in list2" :key="data.name">
            <router-link
              :to="{
                path: data.url,
                query: { anchor: data.anchor },
              }"
            >
              <img :src="data.img" alt="" />
              <h4>{{ data.name }}</h4>
              <img
                class="right-icon"
                src="@/assets/images/home/shape4.png"
                alt=""
              />
            </router-link>
          </div>
        </li>
        <li>
          <div v-for="data in list3" :key="data.name">
            <router-link
              :to="{
                path: data.url,
                query: { anchor: data.anchor },
              }"
            >
              <img :src="data.img" alt="" />
              <h4>{{ data.name }}</h4>
              <img
                class="right-icon"
                src="@/assets/images/home/shape4.png"
                alt=""
              />
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import img1 from "@/assets/images/home/icon1.png";
import img2 from "@/assets/images/home/icon2.png";
import img3 from "@/assets/images/home/icon3.png";
import img4 from "@/assets/images/home/icon4.png";
import img5 from "@/assets/images/home/icon5.png";
import img6 from "@/assets/images/home/icon6.png";
import img7 from "@/assets/images/home/icon7.png";
import img8 from "@/assets/images/home/icon8.png";
import img9 from "@/assets/images/home/icon9.png";
export default {
  name: "block-chain",
  data() {
    return {
      list1: [
        {
          name: "So how do these blocks work?",
          url: "/blockchain-detail",
          anchor: "blockchain1",
          img: img1,
        },
        {
          name: "What problems does blockchain solve?",
          url: "/blockchain-detail",
          anchor: "blockchain4",
          img: img2,
        },
        {
          name: "Proof of Work (PoW)",
          url: "/blockchain-detail",
          anchor: "blockchain7",
          img: img3,
        },
      ],
      list2: [
        {
          name: "Now that we know that, how does the blockchain system work?",
          url: "/blockchain-detail",
          anchor: "blockchain2",
          img: img4,
        },
        {
          name: "Peer-to-peer (P2P)",
          url: "/blockchain-detail",
          anchor: "blockchain5",
          img: img5,
        },
        {
          name: "Proof of Stake (PoS)",
          url: "/blockchain-detail",
          anchor: "blockchain8",
          img: img6,
        },
      ],
      list3: [
        {
          name: "Is Blockchain Safe?",
          url: "/blockchain-detail",
          anchor: "blockchain3",
          img: img7,
        },
        {
          name: "Blockchain Mining",
          url: "/blockchain-detail",
          anchor: "blockchain6",
          img: img8,
        },
        {
          name: "Where to for Blockchain?",
          url: "/blockchain-detail",
          anchor: "blockchain9",
          img: img9,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.blockchain {
  width: 100%;
  background: #161616;
  margin-top: 80px;
  &-content {
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0;
    color: #fff;
    & > h2 {
      font-family: TechnaSans-Regular, TechnaSans;
    }
    & > p {
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      margin-top: 10px;
    }
    &-more {
      margin-top: 10px;
      display: flex;
      align-items: center;
      &:hover {
        a {
          color: #ffc140;
        }
      }
      a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #fff;
      }
      img {
        width: 8px;
        height: 12px;
        margin-left: 10px;
      }
    }
    & > ul {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      justify-content: space-between;
      width: 100%;
      li:first-child {
        width: 389px;
      }
      li:nth-child(2) {
        width: 418px;
      }
      li:last-child {
        width: 353px;
      }
      li {
        display: flex;
        flex-direction: column;
        & > div {
          cursor: pointer;
          height: 80px;
          display: flex;
          flex-direction: row;
          align-items: center;
          background: #1d1d1d;
          border-radius: 1px;
          margin-top: 20px;
          padding: 0 20px;
          position: relative;
          box-sizing: border-box;
          border: 1px solid #1d1d1d;
          & > a {
            display: flex;
            width: 100%;
            height: 100%;
            padding-right: 30px;
            align-items: center;
            background: #1d1d1d;
            color: #fff;
          }
          &:hover {
            .right-icon {
              display: block;
            }
            border: 1px solid #ffc140;
            box-sizing: border-box;
          }
          img {
            width: 42px;
            height: 42px;
          }
          .right-icon {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 30px;
            right: 20px;
            display: none;
          }
          h4 {
            width: 286px;
            font-family: TechnaSans-Regular, TechnaSans;
            margin-left: 18px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
</style>
