<template>
  <div class="detail">
    <img :src="imgUrl" alt="" />
    <ul>
      <li :id="data.anchor" v-for="data in list" :key="data.key">
        <div
          v-if="$route.query.anchor && data.anchor === $route.query.anchor"
          style="background: rgba(255, 193, 64, 0.1)"
        >
          <h2>{{ data.title }}</h2>
          <h4 v-for="c in data.content" :key="c">{{ c }}</h4>
        </div>
        <div v-else>
          <h2>{{ data.title }}</h2>
          <h4 v-for="c in data.content" :key="c">{{ c }}</h4>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "common-detail",
  props: {
    imgUrl: String,
    list: Object,
  },
  mounted() {
    console.log(this.$route.query.anchor);
    console.log(this.list);
  },
};
</script>

<style lang="less" scoped>
.detail {
  width: 1200px;
  margin: 0 auto;
  padding-top: 58px;
  padding-bottom: 58px;
  & > img {
    width: 1200px;
    height: 560px;
    margin-bottom: 42px;
  }
  & > ul {
    li {
      margin-top: 38px;
      &:first-child {
        margin-top: 0;
      }
      & > div {
        padding: 20px;
        & > h2 {
          font-family: TechnaSans-Regular, TechnaSans;
          font-weight: 400;
          color: #161616;
        }
        & > h4 {
          font-weight: 400;
          color: #161616;
          line-height: 28px;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
