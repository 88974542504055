<template>
  <div class="nft">
    <div class="nft-top">
      <h2>NFT’s</h2>
    </div>
    <div class="nft-content">
      <nft-card />
    </div>
  </div>
</template>

<script>
import NftCard from "@/components/nft-card/index.vue";
export default {
  name: "nft-v",
  components: {
    NftCard,
  },
};
</script>

<style lang="less" scoped>
.nft {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 110px;
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > h2 {
      font-size: 32px;
      font-family: TechnaSans-Regular, TechnaSans;
      font-weight: 400;
      color: #161616;
      line-height: 41px;
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: -8px;
    :first-child {
      margin-left: 0;
    }

    :nth-child(4) {
      margin-left: 0;
    }

    &-card {
      width: 386px;
      height: 329px;
      background: url("@/assets/images/home/view.png") no-repeat;
      margin-top: 32px;
      margin-left: 20px;
      padding: 53px 43px 32px 43px;
      display: flex;
      flex-direction: column;
      color: #161616;

      & > h4 {
        font-family: TechnaSans-Regular, TechnaSans;
      }

      & > p {
        margin-top: 10px;
        a {
          text-decoration: underline;
          cursor: pointer;
          color: #161616;
        }
      }

      & > div {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 50px;

        & > img {
          width: 100px;
          height: 100px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
