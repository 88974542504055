<template>
  <div class="currency">
    <div class="currency-top">
      <div class="currency-top-left">
        <router-link
          :to="{ path: '/xtacademy-detail', query: { anchor: 'xtacademy0' } }"
        >
          <img src="@/assets/images/home/pic_1.png" alt="" />
          <h2>what is Cryptocurrency</h2>
        </router-link>
      </div>
      <currency-card :cardData="card1Data" />
    </div>
    <div class="currency-bottom">
      <currency-card :cardData="card2Data" />
      <div class="currency-bottom-right">
        <img src="@/assets/images/home/pic_4.png" alt="" />
        <div class="currency-bottom-right-content">
          <h2>
            {{ "What is this Blockchain Technology I keep hearing about?" }}
          </h2>
          <learn-more url="/xtacademy-detail" anchor="xtacademy3" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyCard from "@/components/currency-card/index.vue";
import LearnMore from "@/components/learn-more/index.vue";
import pic_2Image from "@/assets/images/home/pic_2.png";
import pic_3Image from "@/assets/images/home/pic_3.png";
export default {
  name: "currency-v",
  components: {
    CurrencyCard,
    LearnMore,
  },
  data() {
    return {
      card1Data: {
        key: 1,
        title: "So what's the difference with normal currency?",
        content:
          "Many of you might be wondering, what's wrong with the USD, or the Euro? What can Cryptocurrency do that the cash in my wallet can't do? These are very valid questions and being able to distinguish the difference will help you better appreciate why cryptocurrencies have been gaining such popularity.",
        imgUrl: pic_2Image,
        url: "/xtacademy-detail",
        anchor: "xtacademy1",
        // url: "/currency-detail",
      },
      card2Data: {
        key: 2,
        title:
          "We can't talk about Cryptocurrency without talking about Bitcoin",
        content:
          "When you hear about cryptocurrency, the first thing that will probably come to your mind is Bitcoin. Bitcoin has been a trailblazer in the crypto world. Turning crypto from a mere concept, into a mainstream investment option.",
        imgUrl: pic_3Image,
        url: "/xtacademy-detail",
        anchor: "xtacademy2",
      },
    };
  },
};
</script>

<style lang="less" scoped>
.currency {
  display: flex;
  flex-direction: column;
  color: #ffffff;

  &-top {
    display: flex;
    flex-direction: row;

    &-left {
      width: 375px;
      height: 375px;
      position: relative;
      margin-right: 20px;
      // cursor: pointer;
      &:hover {
        box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
      }

      h2 {
        position: absolute;
        width: 295px;
        top: 40px;
        left: 40px;
        font-size: 32px;
        font-family: TechnaSans-Regular, TechnaSans;
        line-height: 41px;
        color: #fff;
      }
    }
  }

  &-bottom {
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    &-right {
      width: 375px;
      height: 375px;
      position: relative;
      margin-left: 20px;
      &:hover {
        box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
      }

      &-content {
        position: absolute;
        left: 40px;
        top: 40px;

        & > h2 {
          width: 295px;
          margin-bottom: 109px;
          font-size: 32px;
          font-family: TechnaSans-Regular, TechnaSans;
          font-weight: 400;
          color: #ffffff;
          line-height: 41px;
        }
      }
    }
  }
}
</style>
