<template>
  <div class="card">
    <img :src="cardData.imgUrl" alt="" />
    <div class="card-content">
      <div>
        <h2>{{ cardData.title }}</h2>
        <h5>
          {{ cardData.content }}
        </h5>
      </div>
      <learn-more :url="cardData.url" :anchor="cardData.anchor" />
    </div>
  </div>
</template>

<script>
import LearnMore from "@/components/learn-more/index.vue";
export default {
  name: "currency-card",
  components: {
    LearnMore,
  },
  props: {
    cardData: Object,
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
