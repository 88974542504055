<template>
  <common-detail :imgUrl="imgUrl" :list="list" />
</template>

<script>
import CommonDetail from "@/components/commonDetail/index.vue";
import nft4Image from "@/assets/images/detail/trading.png";
export default {
  name: "trading-detail",
  components: {
    CommonDetail,
  },
  data: function () {
    return {
      imgUrl: nft4Image,
      list: [
        {
          title: "Trading",
          content: [
            "As you would with fiat currencies, trading is just as common in the crypto world.",
            "As you might have heard, blockchain and cryptocurrencies are already used in a lot of different areas. Undoubtedly, one of the biggest current use cases is speculation.",
            "Trading generally implies a shorter-term approach to generating profit. Traders may jump in and out of positions all the time. But how do they know when to get in and out?",
            "One of the most common ways to make sense of the cryptocurrency market is through an approach called technical analysis (TA). Technical analysts look at price history, charts, and other types of market data to find bets that have a good chance of returning a profit.",
            "You must be dying to get started right away. And technically, you could. It's that easy! But, like most things worth pursuing, trading is hard! It would take us a long time to talk about all that you need to keep in mind.",
          ],
        },
      ],
    };
  },
};
</script>
