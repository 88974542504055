<template>
  <div class="banner">
    <div class="banner-content">
      <h1 class="banner-title">XTAcademy</h1>
      <h4 class="banner-text">
        {{
          "Welcome to XTAcademy. Whether you've already got some cryptocurrency knowledge, or you're just curious as to what the hype is about crypto, XTAcademy is here for you."
        }}
      </h4>
      <router-link :to="'/xtacademy-detail'">
        <div class="banner-btn">
          <h4>Start Learning</h4>
          <img src="@/assets/images/home/shape.png" alt="" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "banner-v",
};
</script>

<style lang="less" scoped>
.banner {
  height: 458px;
  background: url("@/assets/images/home/bg_1.png") no-repeat;
  background-size: cover;

  &-content {
    width: 1200px;
    margin: 0 auto;
    color: #fcfcfd;
    padding-top: 80px;

    > h1 {
      font-size: 72px;
      font-family: TechnaSans-Regular, TechnaSans;
      font-weight: 400;
      line-height: 90px;
    }

    > h4 {
      width: 535px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 28px;
      // opacity: 0.85;
    }

    .banner-btn {
      width: 228px;
      height: 44px;
      background: #ffd200;
      border-radius: 22px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background: #ffffff;
      }
      > h4 {
        font-family: TechnaSans-Regular, TechnaSans;
        font-weight: 400;
        color: #161616;
        line-height: 26px;
      }

      > img {
        width: 16px;
        height: 16px;
        margin-left: 15px;
      }
    }
  }
}
</style>
