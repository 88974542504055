<template>
  <common-detail :imgUrl="imgUrl" :list="list" />
</template>

<script>
import CommonDetail from "@/components/commonDetail/index.vue";
import nft2Image from "@/assets/images/detail/nft2.png";
export default {
  name: "ntf-detail2",
  components: {
    CommonDetail,
  },
  data: function () {
    return {
      imgUrl: nft2Image,
      list: [
        {
          title: "So what can I do with an NFT?",
          content: [
            "Great question, imagine playing a video game where you play against other players from around the world. Before you play, you get to choose your character and maybe what your character wears or looks like. It is possible that you buy an NFT outfit for your character to wear, or a unique hairstyle that only your character will have the rights to own. This may seem trivial, but many gamers already pay for extras for their games, people already pay more for extra features in their social media, NFT's will help creators be properly reimbursed while users can have a more unique, individualised experience.",
          ],
        },
      ],
    };
  },
};
</script>
